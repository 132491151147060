.app-date-picker {
    height: 50px;
    padding: 2px 50px 2px 12px;
    width: 100%;
    cursor: default;
    color: #495057;
    font-family: $ox-font-neutrif;
    border: 1px solid $ox-gray;

    &::placeholder {
        color: $ox-gray;
    }

    &:hover {
        border-color: #ed2b30;
    }

    &:focus {
        background: $white;
        border: 1px solid $ox-red;
        box-shadow: 0 0 0 0.2rem rgba(237, 43, 48, 0.25);

        &::placeholder {
            color: $white;
        }
    }

    &:focus-visible {
        outline: none;
    }
}
