.nav-container {
    .nav-container-border {
        border-bottom: 1px solid $ox-gray-5;
    }

    .cart-icon-container {
        .cart-counter {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1rem;
            width: 1rem;
            border-radius: 50%;
            background-color: $ox-red;
            top: -5px;
            right: -5px;
        }
    }

    .nav-avatar {
        border: 2px solid $ox-gray;
        border-radius: 50%;
    }
}

.login-modal {
    .modal-dialog {
        max-width: 395px;
    }
}
