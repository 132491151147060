.rating-card-container {
    .rating-avatar {
        padding: 2px;
        border: 2px solid $ox-red;
        object-fit: cover;
    }
    .rating-card-main {
        background: $white;
    }
}
