.t-card-img-square {
    .t-card-img-wrapper {
        &::after {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }
}

.t-card-img-rect {
    .t-card-img-wrapper {
        &::after {
            content: '';
            display: block;
            padding-top: 118.73%;
        }
    }
}

.ribbon-container {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    right: -5px;
    top: 28px;
    z-index: 10;

    &::after {
        position: absolute;
        content: '';
        background-color: #b72225;
        width: 6px;
        height: 5px;
        bottom: -5px;
        right: 0px;
    }

    .ribbon {
        height: 26px;
        padding: 0 1rem 0 2rem;
        background-color: $ox-red;
        clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
    }
}

.t-card-container {
    cursor: pointer;

    .template-hover-overlay {
        opacity: 0;
    }

    &:hover {
        .t-card-img-wrapper {
            border-color: $ox-red;
        }

        .template-hover-overlay {
            opacity: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(77, 89, 104, 0.93);
            transition: 0.2s ease-in;
        }
    }

    .t-card-img-wrapper {
        position: relative;
        overflow: hidden;
        border: 1px solid transparent;

        .t-card-img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
        }

        .t-card-logo {
            position: absolute;
            top: 5%;
            left: 7%;
            width: 25%;
        }

        .t-card-img-desc {
            position: absolute;
            bottom: 5%;
            left: 7%;
            right: 7%;
        }
    }
}
