.t-skeleton-img-square {
    .t-skeleton-img-wrapper {
        &::after {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }
}

.t-skeleton-img-rect {
    .t-skeleton-img-wrapper {
        &::after {
            content: '';
            display: block;
            padding-top: 118.73%;
        }
    }
}

.t-skeleton-container {
    .t-skeleton-img-wrapper {
        position: relative;
        overflow: hidden;
        border: 1px solid transparent;

        .t-skeleton-img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
        }

        .t-skeleton-logo {
            position: absolute;
            top: 5%;
            left: 7%;
            width: 25%;
        }

        .t-skeleton-img-desc {
            position: absolute;
            bottom: 5%;
            left: 7%;
            right: 7%;
        }
    }
}

.p-skeleton-container {
    .p-skeleton-img-wrapper {
        position: relative;
        overflow: none;

        &::after {
            content: '';
            display: block;
            padding-top: 100%;
        }

        .p-skeleton-img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
        }
    }

    .p-skeleton-detail-wrapper {
        background-color: $white;
    }
}
