.v-trainers-container {
    overflow-x: scroll;
    display: flex;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
