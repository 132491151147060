.app-table-input {
    width: 140px;
    height: 32px;

    input {
        border: 1px solid $ox-light-gray-1;
        border-radius: 4px;
        text-align: center;
        font-family: $ox-font-neutrif;
        font-weight: 400;
        color: $ox-gray-2;
        font-size: 0.875rem;
        width: 100%;

        &:focus,
        &:focus-visible {
            font-weight: 500;
            color: $black;
            border: 1px solid $ox-gray;
            outline-width: 0;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
}
