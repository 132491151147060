.feat-topic-container {
    .feat-topic-wrapper {
        background-size: cover !important;
        background-position: center !important;
        cursor: pointer;
        position: relative;
        overflow: auto;
        transition: background 0.15s ease-in-out;

        &:hover {
            transform: scale(1.01);
        }

        &::after {
            content: '';
            display: block;
            padding-top: 75%;
        }

        .feat-topic-desc {
            position: absolute;
            bottom: 0;
        }
    }
}

.f-detail-main-container {
    background-color: $white;
}
