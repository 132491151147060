$counter-bg: #f5f7ff;

.counter-container {
    background-color: $counter-bg;
    border-radius: 0.375rem;

    .counter-icon-wrapper {
        color: $ox-gray;
        &:hover {
            color: $ox-dark-blue;
        }
    }
}
