.app-search {
    .rbt-input-main {
        height: 50px;

        font-family: $ox-font-neutrif;
        border: 1px solid $ox-gray;

        &::placeholder {
            color: $ox-gray;
        }

        &:focus {
            background: transparent;
            border: 1px solid $ox-red;

            &::placeholder {
                color: $white;
            }
        }
    }
}
