$stats-item-bg: #edf1f5;

.dashboard-nav {
    border-bottom: 1px solid $ox-gray-5;
    border-top: 1px solid $ox-gray-5;

    .dashboard-nav-link {
        color: $ox-gray-1;
    }

    .dashboard-nav-link-active {
        color: $ox-red;
    }
}

.settings-nav {
    min-height: 412px;

    .settings-nav-link {
        border-left: 3px solid transparent;
    }

    .settings-nav-link-active {
        background-color: #fcfcfc;
        border-left: 3px solid $ox-red;

        svg {
            color: $ox-red;
        }
    }
}

.image-upload-input[type='file'] {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;

    &::-webkit-file-upload-button {
        cursor: pointer;
    }
}

.profile-avatar-container {
    display: inline-block;
    .profile-avatar {
        border: double 3px transparent;
        background-image: linear-gradient(white, white),
            radial-gradient(circle at top left, #ed2b30, #003381);
        background-origin: border-box;
        background-clip: content-box, border-box;
        object-fit: cover;
    }

    .image-overlay {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        background-color: $ox-gray;
    }

    .image-upload-overlay {
        opacity: 0;
    }

    &:hover {
        .image-upload-overlay {
            opacity: 0.8;
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
        }
    }
}

.dashboard-container {
    .profile-info-container {
        background: url('https://images.pexels.com/photos/841130/pexels-photo-841130.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500')
            rgba(28, 32, 38, 0.75);
        background-blend-mode: overlay;
        background-size: cover;
        border-radius: 5px;
    }

    .stats-container {
        .stats-item {
            height: 100%;
            background-color: $stats-item-bg;
            border-radius: 5px;
        }
    }
}

.consultation-table {
    border: 1px solid $stats-item-bg;
    thead {
        background-color: $stats-item-bg;
    }

    tbody {
        tr {
            border-bottom: 1px solid $stats-item-bg;

            .profile-avatar {
                border: 2px solid $ox-red;
            }

            .type-container {
                border-radius: 10px;
                min-width: 84px;
            }

            .type-online {
                background-color: #cdffcd;
                color: #007f00;
            }

            .type-upcoming {
                background-color: #fff7cd;
                color: #f2994a;
            }

            .type-offline {
                background-color: lighten($color: $ox-red, $amount: 40%);
                color: $ox-red;
            }
        }
    }
}

.order-table {
    border: 1px solid $stats-item-bg;
    thead {
        background-color: $stats-item-bg;
    }

    tbody {
        .order-row {
            &:hover {
                background-color: $ox-background-1;
            }
        }

        tr {
            border-bottom: 1px solid $stats-item-bg;

            .order-img-wrapper {
                img {
                    height: 125px;
                    width: 103px;
                    object-fit: cover;
                }
            }

            .type-container {
                border-radius: 10px;
                min-width: 84px;
            }

            .type-delivered {
                background-color: #cdffcd;
                color: #007f00;
            }

            .type-pending {
                background-color: #fff7cd;
                color: #f2994a;
            }

            .type-cancelled {
                background-color: lighten($color: $ox-red, $amount: 40%);
                color: $ox-red;
            }
        }
    }
}

.hr-gray {
    border-color: $ox-gray;
}

.calendar-consultation {
    width: auto;
    border: 1px solid $ox-gray-5;
    border-radius: 5px;

    .react-calendar__viewContainer {
        padding: 0px 1rem 3rem;
    }

    .react-calendar__navigation__label {
        font-family: $ox-font-neutrif;
        font-weight: 600;
        font-size: 0.875rem;
    }

    .react-calendar__month-view__weekdays {
        font-family: $ox-font-inter;
        font-weight: 600;
        font-size: 0.75rem;

        abbr {
            text-decoration: none;
            color: $ox-gray-2;
        }
    }

    .react-calendar__month-view__days {
        border: 0.5px solid $ox-gray-4;
    }

    .calendar-consultation-tile {
        font-family: $ox-font-inter;
        font-weight: 400;
        font-size: 0.75rem;
        color: $ox-gray-2;
        border: 0.5px solid $ox-gray-4;
        padding: 2em 0.5em;
    }

    .react-calendar__tile--active {
        background: $ox-dark-blue;
        color: $white;
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
        background: $ox-gray-3;
        color: $white;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: $ox-dark-blue;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        background-color: #fcfcfc;
        color: #747474;
    }

    .react-calendar__tile--now {
        background-color: $ox-gray-4;
        color: $ox-dark-blue;
        font-weight: 600;
    }
}

.consultation-time-slot {
    border-radius: 5px;
    background-color: $ox-light-gray-1;

    .slick-prev,
    .slick-next {
        left: 0;
        right: 0;
        margin: auto;
        height: 26px;
        width: 40px;
    }
    .slick-next {
        top: unset;
        bottom: -14px;
    }
    .slick-prev {
        top: 14px;
    }

    .time-slot-container {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 100%;

        .time-slot {
            height: 30px;
            width: 78px;
            background-color: $white;
            text-align: center;
            border-radius: 20px;
            @include box-shadow(0, 1px, 2px, 1px, rgba(0, 0, 0, 0.2));
        }

        .time-slot-active {
            transform: scale(1.2);
            background-color: $ox-dark-blue;
            border-radius: 30px;
            transition: 0.2s ease-in;

            span {
                color: $white !important;
            }
        }
    }
}

.booking-detail {
    background-color: $ox-dark-blue;
    border-radius: 5px;
}

.address-tabs {
    border-bottom: 1px solid $ox-gray;

    .nav-link {
        color: $ox-dark-3;
        font-family: $ox-font-neutrif;
        padding-left: 0px;
        font-size: 1rem;
        border: none;
        margin-right: 1rem;
    }

    .active {
        font-weight: 600 !important;
        background-color: unset !important;
        color: $ox-red !important;
        border-bottom: 2px solid $ox-red !important;
    }
}

.address-table {
    thead {
        th {
            &:first-child {
                border-radius: 5px 0 0 5px;
            }
            &:last-child {
                border-radius: 0 5px 5px 0;
            }
        }
    }

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border: 2px solid $ox-gray;
        border-radius: 50%;
        cursor: pointer;
        background-color: $white;
    }
}
