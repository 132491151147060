$black: #000;
$white: #fff;

$ox-red: #ed2b30;
$ox-dark-blue: #1c2026;
$ox-dark-2: #1b2233;
$ox-dark-3: #4f5668;
$ox-dark-4: #495057;
$ox-gray: #a4a6a8;
$ox-gray-1: #828282;
$ox-gray-2: #4f4f4f;
$ox-gray-3: #373f4b;
$ox-gray-4: #dfdfdf;
$ox-gray-5: #e0e0e0;
$ox-light-gray: #f2f2f2;
$ox-light-gray-1: #edf1f5;
$ox-light-gray-2: #f8f9fb;
$ox-light-gray-3: #f4f6fc;
$ox-dark-gray: #282c33;
$ox-dark-gray-1: #333333;
$ox-dark-gray-2: #797c7f;
$ox-background: #e5e5e5;
$ox-background-1: #f5f5f5;
$ox-background-2: #eaeff3;
$ox-background-3: #f1f4f9;
$ox-yellow: #ffc806;
$ox-link: #2f80ed;
$ox-blue: #1f356d;

$ox-font-oswald: 'Oswald', sans-serif;
$ox-font-neutrif: 'Neutrif Pro';
$ox-font-inter: 'Inter', sans-serif;

$box-shadow: 0 2px 5px rgba($black, 0.3);

$theme-colors: (
    'primary': $ox-red,
    'secondary': $ox-dark-blue,
);

$enable-rounded: false;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1900px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    xxxl: 1600px,
);

$link-color: $ox-dark-blue;
$link-hover-color: $ox-red;
$link-hover-decoration: none;
