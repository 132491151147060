$template-background-color: #f1f4f9;
$template-border: 2px solid #c1ccdd;

.t-details-container {
    .template-btn {
        width: 100%;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .template-contents-container {
        border: $template-border;
        background-color: $template-background-color;
    }

    .template-review-dropdown {
        .btn-outline-secondary {
            border: none;
            font-family: $ox-font-neutrif;
            font-weight: 600;
            font-size: 0.875rem;
        }

        .dropdown-toggle {
            &::after {
                margin-left: 0.5rem;
            }
        }

        .dropdown-item {
            font-family: $ox-font-inter;
            font-weight: 500;

            &:active {
                background-color: $ox-light-gray;
            }
        }
    }
}
