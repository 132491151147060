.coach-active {
    background-color: $ox-background-3;
    border: 1px solid #c1ccdd;

    svg {
        color: $ox-red;
    }
}

.coach-inactive {
    background-color: $white;
    border: 1px solid $ox-light-gray;

    svg {
        color: $ox-gray;
    }
}
