.radio-container {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .radio-outer-circle {
        width: 1.5rem;
        height: 1.5rem;
        min-width: 1.5rem;
        min-height: 1.5rem;
        border: 2px solid $ox-red;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
        transition: all 0.1s linear;
        background-color: $white;
    }

    .radio-inner-circle {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background-color: $ox-red;
        transition: all 0.1s linear;
    }
    .unselected {
        border: 2px solid $ox-gray;
    }
    .unselected-circle {
        width: 0;
        height: 0;
    }
    .helper-text {
        padding-right: 0.5 rem;
    }
}
