$google-bg: #ffffff;
$facebook-bg: #3366ff;
$linkedIn-bg: #1326cb;
$social-light-color: #eceff1;
$social-dark-color: #4f4f4f;

.app-btn {
    font-family: $ox-font-oswald;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    border-width: 2px;
    height: 50px;
    padding-left: 3rem;
    padding-right: 3rem;
}

.social-btn {
    display: inline-flex;
    align-items: center;
    font-family: $ox-font-neutrif;
    font-weight: 700;
    font-size: 14px;
    height: 50px;
    outline: none;
    border: none;

    @include box-shadow(0, 1px, 2px, 1px, rgba(0, 0, 0, 0.2));
}

.facebook-btn {
    background-color: $facebook-bg;
    color: $social-light-color;

    &:hover {
        background-color: darken($color: $facebook-bg, $amount: 8%);
    }
}

.google-btn {
    background-color: $google-bg;
    color: $social-dark-color;

    &:hover {
        background-color: darken($color: $google-bg, $amount: 8%);
    }
}

.linkedIn-btn {
    background-color: $linkedIn-bg;
    color: $social-light-color;

    &:hover {
        background-color: darken($color: $linkedIn-bg, $amount: 8%);
    }
}
