.f-card-container {
    background-color: $white;

    .f-card-img-wrapper {
        .f-card-img {
            width: 188px;
            height: 171px;
            object-fit: cover;
        }
    }
}
