.training-details {
    .training-details-table {
        border-collapse: collapse;
        border-style: hidden;

        th {
            border: none;
        }

        th,
        td {
            text-align: center;
            &:first-child {
                text-align: left;
            }
        }

        td {
            border: 1px solid $ox-light-gray;
        }

        .add-icon {
            svg {
                background-color: $white;
                border-radius: 4px;
            }
        }
    }

    .training-details-table-blur {
        th,
        td {
            border: none;
        }

        background: rgba(164, 166, 168, 0.62);
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -ms-filter: blur(5px);
        -o-filter: blur(5px);
        filter: url("data:image/svg+xml;utf8,<svg height='0' xmlns='http://www.w3.org/2000/svg'><filter id='svgBlur' x='-5%' y='-5%' width='110%' height='110%'><feGaussianBlur in='SourceGraphic' stdDeviation='5'/></filter></svg>#svgBlur");
        filter: blur(5px);
    }

    .table-lock {
        left: 50%;
        bottom: 50%;
        background-color: $white;
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .training-progress {
        border-radius: 10px;
        height: 0.5rem;

        .progress-bar {
            border-radius: 10px;
        }
    }
}
