.product-counter-container {
    overflow: hidden;

    .product-counter-icon-wrapper {
        border: 1px solid $ox-gray;
        color: $ox-gray;

        &:hover {
            background-color: $ox-dark-blue;
            border: 1px solid $ox-dark-blue;
            color: $white;
            transition: all 0.1s ease-in;
        }
    }
}

.product-counter-lg {
    height: 40px;
}

.product-counter-sm {
    height: 28px;
}
