.app-breadcrumbs {
    .breadcrumb {
        background: $white;
        font-family: $ox-font-neutrif;
    }

    .breadcrumb-dark {
        li a {
            color: $white;
        }

        .disabled-link {
            pointer-events: none;
            color: $white;
        }
    }

    .breadcrumb-light {
        li a {
            color: $ox-gray;
        }

        .disabled-link {
            pointer-events: none;
            color: $ox-dark-blue;
        }
    }

    ul {
        li {
            list-style: none;
            &:not(:first-child) {
                &::before {
                    float: left;
                    padding: 0 0.5rem;
                    color: $ox-gray;
                    content: '>';
                }
            }
        }
    }
}
