.trainer-card-container {
    max-width: 298px;
    cursor: pointer;

    &:hover {
        .trainer-card-img-wrapper {
            border-color: $ox-red;
        }
    }

    .trainer-card-img-wrapper {
        position: relative;
        overflow: auto;
        border: 1px solid transparent;

        &::after {
            content: '';
            display: block;
            padding-top: 144.625%;
        }

        .trainer-card-img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
        }
    }
}
