.v-card-container {
    .v-wrapper {
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            display: block;
            padding-top: 56.25%;
        }

        .react-player {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
