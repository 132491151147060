.status-container {
    border-radius: 10px;
    min-width: 90px;
    height: 20px;
}

.status-success {
    background-color: rgba(39, 174, 96, 0.15);
    color: #27ae60;
}

.status-warning {
    background-color: rgba(242, 153, 74, 0.15);
    color: #f2994a;
}

.status-danger {
    background-color: rgba(237, 43, 48, 0.15);
    color: $ox-red;
}
