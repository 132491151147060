.text-shadow-container {
    .text-shadow-main {
        position: relative;
        z-index: 10;
    }

    .text-shadow-span {
        position: absolute;
        font-size: 3.125rem;
        bottom: 0;
        left: 0px;
        right: 0px;
        line-height: 1;
    }
}
