.about-banner-container {
    background-size: cover !important;
    background-position: center !important;
    background: linear-gradient(
            0deg,
            rgba(28, 32, 38, 0.3),
            rgba(237, 43, 48, 0.1)
        ),
        url('../../images/about-banner.jpg');

    .about-heading {
        font-size: 1rem;

        @media screen and (min-width: 768px) {
            font-size: 1.3rem;
        }
    }
}

.activity-container {
    background: $ox-dark-blue;
    color: $white;

    .img-gallery-wrapper {
        margin-left: -15px;
        margin-right: -15px;

        .react-photo-gallery--gallery {
            img {
                object-fit: cover;
            }
        }
    }
}
