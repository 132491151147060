@font-face {
    font-family: 'Neutrif Pro';
    src: url('../fonts/NeutrifPro-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('../fonts/NeutrifPro-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('../fonts/NeutrifPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Neutrif Pro';
    src: url('../fonts/NeutrifPro-Bold.ttf') format('truetype');
    font-weight: 700;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-family: $ox-font-neutrif;
    font-weight: 400;
    color: $ox-dark-blue;
    background: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $ox-font-oswald;
    font-weight: 700;
}

a {
    font-family: $ox-font-oswald;
    font-size: 0.875rem;
}

p {
    font-family: $ox-font-neutrif;
}

.text-black {
    color: $black;
}

.text-white {
    color: $white;
}

.text-red {
    color: $ox-red;
}

.text-gray {
    color: $ox-gray;
}

.text-gray-1 {
    color: $ox-gray-1;
}

.text-gray-2 {
    color: $ox-gray-2;
}

.text-gray-3 {
    color: $ox-gray-3;
}

.text-dark-blue {
    color: $ox-dark-blue;
}

.text-light-gray {
    color: $ox-light-gray;
}

.text-light-gray-1 {
    color: $ox-light-gray-1;
}

.text-light-gray-2 {
    color: $ox-light-gray-2;
}

.text-dark-gray {
    color: $ox-dark-gray;
}

.text-dark-gray-1 {
    color: $ox-dark-gray-1;
}

.text-yellow {
    color: $ox-yellow;
}

.text-dark-2 {
    color: $ox-dark-2;
}

.text-dark-3 {
    color: $ox-dark-3;
}

.text-dark-4 {
    color: $ox-dark-4;
}

.text-link {
    color: $ox-link;
}

.text-blue {
    color: $ox-blue;
}

.text-inter {
    font-family: $ox-font-inter;
}

.text-oswald {
    font-family: $ox-font-oswald;
}

.text-neutrif {
    font-family: $ox-font-neutrif;
}

.text-400 {
    font-weight: 400;
}

.text-500 {
    font-weight: 500;
}

.text-600 {
    font-weight: 600;
}

.text-700 {
    font-weight: 700;
}

.text-10 {
    font-size: 0.625rem;
}

.text-12 {
    font-size: 0.75rem;
}

.text-14 {
    font-size: 0.875rem;
}

.text-16 {
    font-size: 1rem;
}

.text-18 {
    font-size: 1.125rem;
}

.text-20 {
    font-size: 1.25rem;
}

.text-24 {
    font-size: 1.5rem;
}

.text-36 {
    font-size: 2.25rem;
}

.text-48 {
    font-size: 3rem;
}

.text-74 {
    font-size: 4.625rem;
}

.text-underline {
    text-decoration: underline;
}

.bg-white {
    background-color: $white;
}

.bg-red {
    background-color: $ox-red;
}

.bg-dark-blue {
    background-color: $ox-dark-blue;
}

.bg-gray {
    background-color: $ox-background;
}

.bg-gray-1 {
    background-color: $ox-background-1;
}

.bg-light-gray {
    background-color: $ox-light-gray;
}

.bg-light-gray-1 {
    background-color: $ox-light-gray-1;
}

.bg-light-gray-2 {
    background-color: $ox-light-gray-2;
}

.bg-light-gray-3 {
    background-color: $ox-light-gray-3;
}

.bg-dark-gray {
    background-color: $ox-dark-gray;
}

.bg-dark-gray-1 {
    background-color: $ox-dark-gray-1;
}

.bg-dark-gray-2 {
    background-color: $ox-dark-gray-2;
}

.bg-yellow {
    background-color: $ox-yellow;
}

.bg-2 {
    background-color: $ox-background-2;
}

.bg-3 {
    background-color: $ox-background-3;
}

.cursor-pointer {
    cursor: pointer;
}

.text-hover-red {
    &:hover {
        * {
            color: $ox-red;
        }
    }
}

.text-capitalize {
    text-transform: capitalize;
}

.text-hover-dark {
    &:hover {
        * {
            color: $ox-dark-blue;
        }
    }
}

.opacity-0 {
    opacity: 0;
}

.opacity-25 {
    opacity: 0.25;
}

.opacity-75 {
    opacity: 0.75;
}

.white-space-pre-line {
    white-space: pre-line;
}

.object-cover {
    object-fit: cover;
}

.fade-in {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.page-item.active .page-link {
    z-index: 1 !important;
}
