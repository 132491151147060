.app-checkbox {
    border: 2px solid $ox-gray;
    border-radius: 2px;
}

.app-checkbox-lg {
    height: 24px;
    width: 24px;
}

.app-checkbox-sm {
    height: 16px;
    width: 16px;
}

.app-check-label {
    margin-left: 10px;
}
