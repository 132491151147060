.shop-hero {
    background-color: $white;

    .shop-hero-container {
        background: url('../../images/shop-banner.jpg') rgba(28, 32, 38, 0.7);
        background-size: cover;
        background-blend-mode: overlay;
    }
}

.shop-category-list-slider {
    .slick-disabled {
        fill: $ox-gray !important;
    }

    .slick-prev,
    .slick-next {
        z-index: 10;
        fill: $ox-red;
    }

    .slick-prev {
        left: -8px;
    }

    .slick-next {
        right: -6px;
    }

    .slick-list {
        margin-left: -0.5rem;
    }
}

.category-link {
    &:hover {
        .category-container {
            border-bottom-color: $ox-red;

            .category-title {
                color: $ox-red;
            }
        }
    }

    .category-container {
        border-bottom: 3px solid transparent;
        background-color: $white;
        @include box-shadow(0, 2px, 5px, 0, rgba(0, 0, 0, 0.2));

        .category-title {
            color: $ox-dark-blue;
        }
    }
}

.shop-search-container {
    .shop-filter-container {
        background-color: $white;

        .shop-filter-head {
            border-bottom: 1px solid $ox-gray-5;
        }
    }

    .shop-filter-active {
        background-color: rgba(237, 43, 48, 0.06);

        svg,
        span {
            color: $ox-red;
        }
    }
}

.promo-title-text {
    font-size: 1.6rem;

    @media screen and (min-width: 576px) {
        font-size: 2rem;
    }

    @media screen and (min-width: 992px) {
        font-size: 3rem;
    }
}
