$checkout-summar-bg: #f5f7ff;
$border: 1px solid #cacdd8;

.cart-container {
    .cart-item-container {
        .cart-img-wrapper {
            img {
                height: 125px;
                width: 103px;
                object-fit: cover;
            }
        }

        .cart-img-overlay {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.4);
        }

        .edit-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            border: 2px solid $ox-gray;
            border-radius: 50%;
            cursor: pointer;
        }

        .border-bottom-1 {
            border-bottom: $border;
        }
    }
}

.summary-container {
    background-color: $checkout-summar-bg;
}

.checkout-container {
    .merchant-container {
        .btn {
            height: 50px;
            width: 110px;
            padding: 0;
            border: 2px solid transparent;
        }

        .btn-outline-primary {
            border-color: $ox-gray;

            &:hover {
                background-color: $white;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .payment-container {
        background-color: $checkout-summar-bg;
        .cardtype-container {
            img {
                height: 24px;
                width: auto;
                max-width: 64px;
            }
        }
    }
}

.order-confirm-container {
    background-color: $checkout-summar-bg;
}

.delivery-modal {
    .delivery-label-container {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 50px;
        color: $white;
        max-width: 120px;
        width: 100%;
    }

    .delivery-label-inactive {
        background-color: $ox-gray-1;

        &:hover {
            transition: all 0.2s ease-in;
            background-color: $ox-gray-2;
        }
    }

    .delivery-label-active {
        background-color: $ox-dark-blue;
    }
}
