.trainer-profile-container {
    .trainer-cover-image {
        height: 260px;
        background: url('../../images/about-banner.jpg') rgba(28, 32, 38, 0.7);
        background-size: cover;
        background-blend-mode: overlay;
        background-repeat: no-repeat;
        background-position: 0px 70%;
    }

    .trainer-profile-card {
        margin-top: -200px;

        .trainer-profile-img {
            height: 320px;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }

        .trainer-profile-desc {
            background-color: $ox-dark-blue;
        }
    }

    .trainer-detail-container {
        .trainer-links {
            border-bottom: 1px solid $ox-gray-5;

            .trainer-nav-link {
                color: $ox-gray;

                &:hover {
                    color: $ox-dark-blue;
                }
            }
            .nav-link-active {
                color: $ox-red;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -4px;
                    background: $ox-red;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    left: 50%;
                    right: 50%;
                }
            }
        }
    }
}

.trainer-gallery-wrapper {
    .react-photo-gallery--gallery {
        img {
            object-fit: cover;
        }
    }
}

.video-list-slider {
    .slick-list {
        margin-left: -16px;
    }

    .slick-slide {
        padding-left: 16px;
    }

    .slick-next {
        height: 100%;
        right: -2px;
        background: linear-gradient(
            268.11deg,
            #f4f6fc 20.29%,
            rgba(255, 255, 255, 0) 90.89%
        );
    }
}

.trainer-personal-achievement {
    .timeline {
        list-style-type: none;
        position: relative;
        margin: 0;
        padding-left: 8px;

        .timeline-item {
            padding-bottom: 28px;
            padding-left: 50px;
            position: relative;

            &::after {
                content: ' ';
                display: inline-block;
                position: absolute;
                top: 0;
                left: 11px;
                border-left: 2px dashed $ox-gray-5;
                bottom: 0;
            }

            &::before {
                content: ' ';
                background: $ox-red;
                display: inline-block;
                position: absolute;
                border-radius: 50%;
                border: 6.5px solid #ffd8d9;
                left: 0px;
                width: 24px;
                height: 24px;
                z-index: 400;
            }

            &:last-child {
                &::after {
                    border-left: none;
                }
            }
        }
    }
}

.trainer-review {
    .review-masonry-grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -20px;
        width: auto;
    }
    .review-masonry-grid_column {
        padding-left: 20px;
        background-clip: padding-box;
    }

    .trainer-review-card {
        background-color: $ox-light-gray;
        margin-bottom: 20px;

        .trainer-review-img {
            display: block;
            width: 100%;
            object-fit: cover;
            max-height: 612px;
        }

        .trainer-reviews {
            p {
                color: $ox-gray-2;
            }
        }

        .trainer-review-user {
            img {
                border: 1px solid $ox-gray;
                object-fit: cover;
            }
        }
    }
}
