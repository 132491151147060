.p-card-container {
    background-color: $white;
    cursor: pointer;
    @include box-shadow(0, 1px, 2px, 1px, rgba(0, 0, 0, 0.2));
    transition: all 0.15s ease-in-out;

    &:hover {
        transform: scale(1.01);

        .p-card-detail-wrapper {
            .p-cart-add {
                display: inline-block;
            }
        }
    }

    .p-card-img-wrapper {
        position: relative;
        overflow: auto;
        border: 1px solid transparent;

        &::after {
            content: '';
            display: block;
            padding-top: 100%;
        }

        .p-card-img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
        }
    }

    .p-card-detail-wrapper {
        border-top: 1px solid $ox-gray-5;

        .p-cart-add {
            display: none;
            right: 16px;
            bottom: 16px;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
