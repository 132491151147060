.app-footer {
    background: $ox-dark-blue;
    color: $ox-light-gray;

    a {
        font-family: $ox-font-neutrif;
        color: $white !important;

        &:hover {
            color: $ox-red !important;
        }
    }

    .brand-container {
        .brand-desc {
            color: $ox-light-gray;
            line-height: 1.25;
        }
    }

    .foo-nav-links {
        list-style: none;
        padding-left: 0;

        li {
            padding-bottom: 1rem;

            // &:first-child {
            //     a {
            //         color: $ox-red !important;

            //         &:hover {
            //             color: $ox-gray !important;
            //         }
            //     }
            // }

            a {
                font-size: 0.875rem;
            }
        }
    }

    .policy-container {
        .policy-links {
            a {
                font-size: 0.9375rem;

                &:hover {
                    color: $ox-red !important;
                }
            }
        }
    }

    .foo-trademark {
        border-top: 1px solid $ox-gray-2;

        P {
            font-weight: 500;
            font-size: 0.75rem;
            color: $ox-gray;
        }
    }
}
