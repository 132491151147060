.app-accordion {
    .app-accordion-toggle {
        background: $white;
        outline: none;
        border: none;
        padding: 0;
        width: 100%;
    }

    .app-accordion-collapse {
        ul {
            list-style: none;
            margin: 0;
            padding: 0 0 1rem 1rem;

            li {
                padding: 1em 0 0 1em;
                position: relative;

                &:last-child {
                    &::before {
                        height: 1.7em;
                        bottom: auto;
                    }
                }

                &::before {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 0;
                    border-left: 1px solid $ox-gray-5;
                    content: '';
                }

                span {
                    position: relative;
                    padding-left: 0.5rem;

                    &::after {
                        position: absolute;
                        bottom: 50%;
                        left: -1em;
                        display: block;
                        height: 0.4em;
                        width: 1em;
                        border-bottom: 1px solid $ox-gray-5;
                        content: '';
                    }
                }
            }
        }
    }
}
