.t-search-container {
    .template-filter-container {
        border-right: 1px solid $ox-gray-5;
    }
}

.template-filter-dropdown {
    .btn-outline-secondary {
        border-left: none;
        border-top: none;
        border-right: none;
        font-family: $ox-font-inter;
        font-weight: 500;
        font-size: 0.875rem;
    }

    .dropdown-toggle {
        &::after {
            margin-left: 0.5rem;
        }
    }

    .dropdown-item {
        font-family: $ox-font-inter;
        font-weight: 500;

        &:active {
            background-color: $ox-light-gray;
        }
    }
}

.get-offer-container {
    .get-offer-card-container {
        margin: 0 auto;
        max-width: 1024px;
        position: relative;
        min-height: 112px;
        background-position: center;

        &:after {
            content: '\A';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.3);
        }

        .get-offer-desc {
            z-index: 10;
        }
    }
}
