$template-list-bg: rgba(28, 32, 38, 0.02);

.get-notified-container {
    background: $ox-gray-3;
    color: $white;

    .gn-title {
        font-size: 1.5rem;
    }

    .gn-desc {
        max-width: 57rem;
        color: $ox-light-gray;
    }
}

.offers-container {
    .offer-container {
        max-width: 28.75rem;

        h2 {
            font-weight: 700;
            color: $ox-dark-blue;
        }

        p {
            color: $ox-gray;
        }
    }

    .dumb-left {
        top: 0%;
        left: 0;
    }

    .dumb-right {
        bottom: 20%;
        right: 0;
    }
}

.featured-container {
    background: $ox-dark-blue;
    color: $white;

    .bg-arrow-left {
        position: absolute;
        top: 0;
        right: 50%;
        bottom: 0;

        svg {
            height: 100%;
        }
    }

    .slick-list {
        padding: 0 20% 0 0 !important;
    }
}

.template-list-container {
    background: $template-list-bg;

    .slick-list {
        padding: 0 20% 0 0 !important;
    }
}

.about-container {
    background: $ox-dark-blue;
    color: $white;

    .bg-arrow-left {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        svg {
            height: 100%;
        }
    }

    .player-wrapper {
        position: relative;
        padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    }
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.feedback-container {
    .slick-slider {
        .slick-next {
            right: 0;
            width: 40px;
            height: 52px;
        }
    }

    .fb-main {
        max-width: 1080px;

        .fb-image {
            object-fit: cover;
            min-width: 128px;
        }

        .fb-review {
            z-index: 10;
            .fb-quote {
                right: 99%;
                top: -70px;
                width: 88px;
            }
        }
    }

    .bg-arrow-right {
        position: absolute;
        top: 0;
        left: 14%;
        bottom: 0;

        svg {
            height: 100%;
        }
    }

    .bg-arrow-left {
        position: absolute;
        top: 0;
        left: 14%;
        bottom: 20%;

        svg {
            height: 25%;
        }
    }
}

.app-journey-container {
    background: $ox-dark-blue;

    .journey-col {
        @media screen and (min-width: 768px) {
            width: auto !important;
        }

        width: 50%;
    }

    .border-dot {
        div {
            width: 7.875rem;
            margin-top: 50px;
            border-top: 1px dashed $ox-red;
            letter-spacing: 4px;
        }
    }
}

.hero-container {
    min-height: calc(100vh - 70px);

    .hero-sidebar {
        width: 98px;
        border-right: 1px solid $ox-gray-5;

        .dot-container {
            .dot-outer-circle {
                border-radius: 50%;
                cursor: pointer;
                &:hover {
                    .dot-circle {
                        background: $ox-red;
                    }
                }

                .dot-circle {
                    width: 10px;
                    height: 10px;
                    position: relative;
                    line-height: 10px;
                    border-radius: 50%;
                    background: $ox-dark-blue;
                }

                .dot-active {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        border: 2px solid $ox-gray;
                        border-left: 0;
                        border-bottom-right-radius: 16px;
                        border-top-right-radius: 16px;
                        width: 14px;
                        height: calc(100% + 16px);
                        transform: translate(8px, -8px);
                    }
                }
            }
        }

        .scroll-label {
            span {
                writing-mode: vertical-lr;
                transform: rotate(-180deg);
                -webkit-transform: rotate(-180deg);
                -moz-transform: rotate(-180deg);
            }

            svg {
                transform: rotate(-180deg);
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                width: 14px;
            }
        }
    }

    .hero-main {
        width: 100%;
        overflow: hidden;

        .hero-text {
            @media screen and (min-width: 568px) {
                font-size: 4.625rem;
            }
        }

        .bg-arrow-left {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 50%;
            bottom: 0;
            right: 0;

            svg {
                height: 100%;
            }
        }

        .bg-image {
            z-index: -1;
            position: absolute;
            left: 25%;
            top: 0;
            bottom: 0;
            right: 0;
            img {
                object-fit: contain;
                object-position: bottom;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.animated {
    animation-duration: 2.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
.pulse {
    animation-name: pulse;
    animation-duration: 1s;
}
