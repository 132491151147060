.app-input-group {
    width: 100%;

    textarea {
        height: auto !important;
        resize: none;
    }

    .app-input {
        height: 50px;

        font-family: $ox-font-neutrif;
        border: 1px solid $ox-gray;

        &::placeholder {
            color: $ox-gray;
        }

        &:focus {
            background: transparent;
            border: 1px solid $ox-red;

            &::placeholder {
                color: $white;
            }
        }
    }

    .pr-suffix {
        padding-right: 92px;
    }

    .app-input-suffix {
        top: 12px;
        right: 12px;
        max-width: 80px;
        max-height: 50px;

        .form-control:valid {
            display: none;
        }
    }

    .app-input-fill {
        background-color: $white;

        &:focus {
            background-color: $white;
            &::placeholder {
                color: $ox-gray;
            }
        }
    }

    .app-input-transparent {
        background-color: transparent;
    }
}

.disable-feedback {
    .form-control.is-invalid {
        background-image: none;
    }
}
